import {defer, merge, Observable, of, ReplaySubject, throwError} from 'rxjs'
import {catchError, filter, mapTo, publishReplay, refCount} from 'rxjs/operators'
import {HttpErrorResponse, HttpResponse} from '@angular/common/http'
import {GraphQLError} from 'graphql'

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Helper to provide isLoading and error information from an observable making a request
 * @returns three observables
 * - data$: original observable (query$)
 * - isLoading$: observable emitting true when the request start, false when it yields data or errors out
 * - error$: observable emitting an error when the query$ observable errors out, also emits false when the request starts
 */
export function queryWithEffects<T>(query$: Observable<T>): QueryWithEffects<T> {
  // emits when query$ is subscribed to, useful to trigger the loading observable
  const trigger$ = new ReplaySubject(1)

  const data$ = defer(() => {
    trigger$.next(undefined)
    trigger$.complete()

    return query$
  }).pipe(
    // make it compatible to multicast
    publishReplay(1),
    refCount(),
  )

  // emits when data$ starts/stops loading
  const isLoading$ = merge(
    trigger$.pipe(mapTo(true)),
    data$.pipe(
      catchError(() => of(undefined)),
      mapTo(false),
    ),
  )

  const error$ = data$.pipe(
    mapTo(false),
    filter(res => res !== false),
    catchError(err => of(err)),
  )

  return {
    isLoading$,
    error$,
    data$,
  }
}

export interface QueryWithEffects<T> {
  data$: Observable<T>
  isLoading$: Observable<boolean>
  error$: Observable<false | Error>
}

/**
 * Returns a property of `data` if it exists, throws otherwise
 */
export function pickProperty<TObj, TProp extends keyof TObj>(
  data: TObj | undefined | null,
  propertyKey: TProp,
): NonNullable<PickedProperty<TObj, TProp>> {
  if (data && data[propertyKey]) {
    return data[propertyKey]!
  } else {
    throw new Error(`Could not resolve ${String(propertyKey)}`, {cause: data})
  }
}

/**
 * Returns the type of a property of an object
 * e.g. PickedProperty<{foo: {bar: {baz: string}}, 'bar'> = {baz: string}
 */
export type PickedProperty<TObj, TProp extends keyof TObj> = TObj[TProp]

export function hasGraphqlAuthenticationError(response: HttpErrorResponse | HttpResponse<any> | unknown): boolean {
  return hasGraphqlErrorExtensionCode(response, 'UNAUTHENTICATED')
}

export function hasGraphqlForbiddenError(response: HttpErrorResponse | HttpResponse<any> | unknown): boolean {
  return hasGraphqlErrorExtensionCode(response, 'FORBIDDEN')
}

export function hasGraphqlErrorExtensionCode(
  response: HttpErrorResponse | HttpResponse<any> | unknown,
  code: string,
): boolean {
  return getGraphqlErrorsByExtensionCode(response, code).error
}

/**
 * Cater for both HttpErrorResponse and HttpResponse. HttpErrorResponse is a result of an HTTP error.
 * Angular automatically wraps and throws HTTP errors as HttpErrorResponse
 * HttpResponse happens on HTTP 200 OK, but might contain GraphQL errors which we need to fish out
 * @param response
 * @param code
 */
export function getGraphqlErrorsByExtensionCode(
  response: HttpErrorResponse | HttpResponse<any> | unknown,
  code: string,
): {error: boolean; message?: string} {
  const parse = (errors: readonly GraphQLError[]) => {
    const errorsByCode = errors.filter(e => e.extensions?.code === code)

    if (errorsByCode.length > 0) {
      return {
        error: true,
        message: errorsByCode.map(e => e.message).join(';'),
      }
    }

    return {error: false}
  }

  let errorBody
  if (response instanceof HttpErrorResponse) {
    errorBody = response.error
  } else if (response instanceof HttpResponse) {
    errorBody = response.body
  } else {
    return {error: false}
  }

  const errors = errorBody?.errors

  if (errors && Array.isArray(errors) && errors?.length > 0) {
    return parse(errors)
  }

  return {error: false}
}

export function throwGraphqlError(e: HttpErrorResponse | HttpResponse<any> | unknown): Observable<any> {
  if (e instanceof HttpResponse) {
    const errors = e.body?.errors
    if (errors && Array.isArray(errors) && errors.length > 0) return throwError(errors[0])
  }

  return throwError(e)
}
